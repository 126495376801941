import { PushNotificationSubscriptionInput } from '@/__generated__/graphql.ts';
import { MyAvatar } from '@/components/my-avatar.tsx';
import { paths } from '@/routes-utils/paths.ts';
import { useRemovePushSubscription } from '@/routes/_app.p.settings.notifications_.$type/use-remove-push-subscription.ts';
import { pushService } from '@/services/push-service.ts';
import { traceError } from '@/utils/trace-error.ts';
import { useNavigate } from 'react-router';
import { Button } from '@synoptic/ui-kit/button/button.tsx';
import { IconButton } from '@synoptic/ui-kit/button/icon-button.tsx';
import { LogoutIcon } from '@synoptic/ui-kit/icons/react/logout.tsx';
import { SettingsIcon } from '@synoptic/ui-kit/icons/react/settings.tsx';
import { UserCircleIcon } from '@synoptic/ui-kit/icons/react/user-circle.tsx';
import { useIsLaptop, useIsTablet } from '@synoptic/ui-kit/responsive/hooks.ts';
import { signOut } from 'aws-amplify/auth';
import { clsx } from 'clsx';
import { FC, useState } from 'react';
import { useAuthInfo } from '../use-auth-info.ts';
import { MenuActionLink } from './menu/menu-action/menu-action-link.tsx';
import { menuItemLink } from './menu/menu-action/menu-action.css.ts';
import {
  contentContainer,
  heading,
  profileMenu,
  profileMenuButton,
  profileMenuContent,
  profileMenuItem,
  profileMenuTrigger,
  username,
} from './profile-menu.css.ts';
import { useSidebarContext } from './sidebar-context.tsx';

export const ProfileMenu: FC<{
  opened?: boolean;
}> = ({ opened = false }) => {
  const [isMenuShown, setIsMenuShown] = useState(opened);
  const isTablet = useIsTablet();
  const isLaptop = useIsLaptop();
  const authInfo = useAuthInfo();
  const navigate = useNavigate();
  const [logoutLoading, setLogoutLoading] = useState(false);
  const [removePushSubscription] = useRemovePushSubscription();
  const { isCollapsed } = useSidebarContext();

  const logout = async () => {
    setLogoutLoading(true);
    try {
      const subscription = await pushService.getSubscription();
      if (subscription) {
        const { data } = await removePushSubscription(
          subscription as PushNotificationSubscriptionInput,
        );
        if (data?.removePushNotificationsSubscription) {
          await subscription.unsubscribe();
        }
      }
    } catch (err) {
      traceError(
        new Error('Failed to unsubscribe from push notifications on logout', {
          cause: err,
        }),
      );
    }
    await signOut();
    navigate(paths.login);
    setLogoutLoading(false);
  };

  return (
    <div className={profileMenu}>
      <Button
        variant={'tertiary'}
        tabIndex={opened ? -1 : 0}
        aria-label={'Profile menu'}
        aria-expanded={isMenuShown}
        className={profileMenuTrigger}
        onClick={() => !opened && setIsMenuShown((prev) => !prev)}
      >
        <MyAvatar />
        {!isTablet || (isLaptop && !isCollapsed) ? (
          <div className={contentContainer}>
            <div className={heading}>{authInfo.fullName}</div>
            <div className={username}>@{authInfo.username}</div>
          </div>
        ) : null}
      </Button>
      {isMenuShown ? (
        <div className={profileMenuContent}>
          <MenuActionLink
            className={profileMenuItem}
            icon={<UserCircleIcon />}
            label="Profile"
            to={paths.makeUser(authInfo.username)}
          />
          <MenuActionLink
            className={profileMenuItem}
            icon={<SettingsIcon />}
            label="Settings"
            to={paths.settings}
          />
          {(!isTablet || isLaptop) && !isCollapsed ? (
            <Button
              loading={logoutLoading}
              onClick={logout}
              startIcon={<LogoutIcon />}
              className={clsx(menuItemLink, profileMenuButton)}
              variant={'tertiary'}
            >
              Log out
            </Button>
          ) : (
            <IconButton
              loading={logoutLoading}
              onClick={logout}
              className={clsx(menuItemLink, profileMenuButton)}
            >
              <LogoutIcon />
            </IconButton>
          )}
        </div>
      ) : null}
    </div>
  );
};
